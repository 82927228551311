import { Endpoint } from '../Endpoint';
import { HTTPMethod, HTTPRequest, HTTPResponse, Phoenix } from '../Phoenix';
import BusinessConfig, { CheckInQuestion } from './Model/BusinessConfig'

export default class UserController {
  phoenix: Phoenix

  constructor($phoenix: Phoenix) {
    this.phoenix = $phoenix;
  }

  loadConfig(): Promise<HTTPResponse<BusinessConfig>> {
    return this.phoenix.perform(new HTTPRequest<BusinessConfig>(HTTPMethod.get, Endpoint.bizConfig, `/config`));
  }

  updateCheckInQuestions(
    newQuestions: CheckInQuestion[]
  ): Promise<HTTPResponse<BusinessConfig>> {
    return this.phoenix.perform(
      new HTTPRequest<BusinessConfig>(
          HTTPMethod.post, 
          Endpoint.bizConfig, 
          `/config/checkIn/questions`,
          {
            questions: newQuestions
          }
        )
      )
  }
}