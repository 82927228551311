import React, { useContext, useEffect, useState } from 'react'
import './style.scss'

import { Link } from 'react-router-dom';

import backButtonImage from '../../backbutton.png';
import FadeIn from 'react-fade-in';
import PhoenixContext from '../../Phoenix/PhoenixContext';
import BusinessConfigController from '../../Phoenix/Controller/BusinessConfigController';
import BusinessConfig, { CheckInQuestion } from '../../Phoenix/Controller/Model/BusinessConfig'
import { Accordion, Button, Card } from 'react-bootstrap';

import CheckInConfigQuestionsEditModal from './CheckIn/CheckInConfigQuestionsEditModal'

export default function BusinessConfigPage() {
  const phoenix = useContext(PhoenixContext);
  const configController = new BusinessConfigController(phoenix);
  
  const [activeTab] = useState("visual");
  const [config, setConfig] = useState<BusinessConfig | undefined>(undefined);

  useEffect(() => {
    loadConfig();
    // eslint-disable-next-line
  }, []);

  const loadConfig = async () => {
    const response = await configController.loadConfig();
    setConfig(response.body);
  };

  return <div id="businessconfig-container" className="container">
    <FadeIn>
      <Link to={"/admin"} style={{
        fontWeight: 650
      }}>
        <img src={backButtonImage} alt="Back Button" style={{ height: 20, marginRight: 5 }} />Back to Admin Portal
      </Link>
      <h2 className="title">Business Config</h2>
      
      {config === undefined ? 
        <div>
          <h4>Loading...</h4>
        </div> : 
        <div>
          {/* <div className="segmented">
            <label className={activeTab === "visual" ? "checked" : ""}>
              <input 
                type="radio" 
                name="segmented" 
                checked 
                onClick={() => setActiveTab("visual")} 
                readOnly /> 
                Visual Editor
            </label>
            <label className={activeTab === "json" ? "checked" : ""}>
              <input 
                type="radio" 
                name="segmented" 
                onClick={() => setActiveTab("json")} 
                readOnly /> 
                JSON Editor
            </label>
          </div> */}

          {activeTab === "visual" ? 
            <VisualEditor config={config} setConfig={(config) => {
              setConfig(config)
            }} /> : 
            <JSONEditor json={JSON.stringify(config, null, 2)} setJSON={(json: string) => {

            }} />
          }
        </div>
      }
    </FadeIn>
  </div>
}

function VisualEditor(props: {config: BusinessConfig, setConfig: (config: BusinessConfig) => void}) {
  const [showCheckInQuestionsModal, setShowCheckInQuestionsModal] = useState(false);

  return <div>
      <CheckInConfigQuestionsEditModal show={showCheckInQuestionsModal}
        currentQuestions={props.config.checkIn.questions}
        shouldHide={() => {
          setShowCheckInQuestionsModal(false);
        }}
        setConfig={props.setConfig} />
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Weekly Check-in Config
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <h4 style={{display: "inline-block"}}>Questions</h4>
              <button style={{
                  display: "inline-block",
                  marginLeft: 10,
                  border: 'none',
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: '#57B4B3',
                  color: 'white',
                  borderRadius: 8,
                  fontWeight: 'bold'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowCheckInQuestionsModal(true)
                }}>
                  Edit
              </button>
              <ol>
                {props.config.checkIn.questions
                  .sort((one: CheckInQuestion, two: CheckInQuestion) => {
                    return one.title < two.title ? -1 : 1;
                  })
                  .map((question: CheckInQuestion) => {
                    return <li key={question.id}>
                      <div>
                        {question.title}
                        <ul>
                          {question.options.map((option) => {
                            return <li key={option.id}>{option.text}</li>
                          })}
                        </ul>
                      </div>
                    </li>
                })}
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card></Card>
      </Accordion>
  </div>
}

function JSONEditor(props: {json: string, setJSON: (json: string) => void}) {
  return <div className="jsonEditor">
    <textarea value={props.json} onChange={(e) => {
      props.setJSON(e.target.value);
    }}>
      
    </textarea>
  </div>
}