import React, { useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import BusinessConfig, { CheckInQuestion } from '../../../Phoenix/Controller/Model/BusinessConfig'
import PhoenixContext from '../../../Phoenix/PhoenixContext';
import BusinessConfigController from '../../../Phoenix/Controller/BusinessConfigController';

import './style.scss';

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value + 1); // update the state to force render
}

export default function CheckInConfigQuestionsEditModal(props: {
  show: boolean,
  currentQuestions: CheckInQuestion[],
  shouldHide: (() => void),
  setConfig: ((newConfig: BusinessConfig) => void)
}) {
  const phoenix = useContext(PhoenixContext);
  const configController = new BusinessConfigController(phoenix);

  const [saving, setSaving] = useState(false);
  
  const [questions] = useState(props.currentQuestions.map((question) => {
    return Object.assign({}, question)
  }));

  const [hoveredItemIdentifier, setHoveredItemIdentifier] = useState<any>(null);
  
  const forceUpdate = useForceUpdate();

  return <Modal show={props.show}>
    <Modal.Header>
      <h4 className="modal-title">Check In Questions</h4>
    </Modal.Header>
    <Modal.Body>
      {questions.length === 0 ? "No questions." : `${questions.length} question(s)`}
      <p>Click on a question title or option title to edit it.</p>

      <ol>
        {questions.map((question) => {
          return <li key={question.id} className="bizconf-edit-checkInQuestion" onMouseEnter={() => {
            setHoveredItemIdentifier(question.id);
          }} onMouseLeave={() => {
            if (hoveredItemIdentifier === question.id) {
              setHoveredItemIdentifier(null);
            }
          }}>
            <a href={`#${question.id}`} onClick={(e) => {
              e.preventDefault();
              const newTitle = prompt("Change title", question.title);
              if (newTitle !== null) question.title = newTitle;
              forceUpdate();
            }}>{question.title}</a>
            {hoveredItemIdentifier === question.id ?
              <div style={{display: 'inline-block', marginLeft: 10}}>
                <a href="#delete" 
                  style={{fontWeight: 'bold'}}
                  onClick={() => {
                    const index = questions.indexOf(question);
                    questions.splice(index, 1)
                    
                    forceUpdate();
                    setHoveredItemIdentifier(null);
                  }}>
                    Delete
                </a>
              </div> : null}
            <ul>
              {question.options.map((option) => {
                return <li key={option.id} onMouseEnter={() => {
                  setHoveredItemIdentifier("option" + option.id);
                }} onMouseLeave={() => {
                  if (hoveredItemIdentifier === "option" + option.id) {
                    setHoveredItemIdentifier(null);
                  }
                }}>
                  <a href ="#optionchange" onClick={(e) => {
                    e.preventDefault();
                    const newOptionTitle = prompt("Change option title", option.text);
                    if (newOptionTitle !== null) {
                      const index = question.options.indexOf(option);
                      question.options[index].text = newOptionTitle;
                    }
                    forceUpdate();
                  }}>
                    {option.text}
                  </a>
                  {hoveredItemIdentifier === "option" + option.id ?
                    <div style={{display: 'inline-block', marginLeft: 10}}>
                      <a href="#delete" 
                        style={{fontWeight: 'bold'}}
                        onClick={() => {
                          const index = question.options.indexOf(option);
                          question.options.splice(index, 1)
                          
                          forceUpdate();
                          setHoveredItemIdentifier(null);
                        }}>
                          Delete
                      </a>
                    </div> : null}
                </li>
              })}
            </ul>
            <a href="#newoption" onClick={(e) => {
              e.preventDefault();
              const option = prompt("Please type the name of the option for this question \"" + question.title + "\"")
              if (option === null || option === "") {
                alert("You can't leave the option blank.")
                return;
              }

              let newId = question.options.length + 1;
              function idExists(id: number) {
                return question.options.filter((option) => {
                  return option.id === id
                }).length !== 0
              }
              while (idExists(newId)) {
                newId += 1
              }

              question.options.push({
                id: newId,
                text: option,
                createdClientSide: true
              });
              forceUpdate();
            }}>New Option</a>
          </li>
        })}
      </ol>

      <a href="#newquestion" onClick={(e) => {
        e.preventDefault();
        const title = prompt("Please type the title of the question to ask during weekly check-in.")
        if (title === null || title === "") {
          alert("You can't leave your question title blank.")
          return;
        }
        let newId = questions.length + 1;
        function idExists(id: number) {
          return questions.filter((question) => {
            return question.id === id
          }).length !== 0
        }
        while (idExists(newId)) {
          newId += 1
        }
        questions.push({
          id: newId, // the server will assign a new value
          title: title,
          options: []
        })
        forceUpdate();
      }}>New Question</a>

      <div className="button-holder" style={{gridTemplateColumns: "auto auto", marginTop: 10}}>
        <button type="button" 
          className="btn" 
          data-dismiss="modal" 
          onClick={props.shouldHide} 
          style={{backgroundColor: '#5A5A5A'}}>
            Cancel
        </button>
        <button type="button" 
          className="btn" 
          style={{backgroundColor: '#167efb'}} 
          disabled={saving}
          onClick={async (e) => {
            if (saving) return;

            setSaving(true);
            const response = await configController.updateCheckInQuestions(questions);
            setSaving(false)
            if (response.body !== undefined) props.setConfig(response.body)
            props.shouldHide()
          }}>
            {saving ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal.Body>
  </Modal>
}