import React, { useContext, useEffect, useState } from 'react';
import FadeIn from 'react-fade-in'
import OrgChart from '../OrgChart/OrgChart.js'
import CreateOrganizationModal from './OrganizationList/Modal/CreateOrganizationModal.js'

import backButtonImage from '../backbutton.png';
import accountImage from './accountimg.svg';

import { Link, useHistory } from 'react-router-dom';

import OrganizationController from '../Phoenix/Controller/OrganizationController';
import PhoenixContext from '../Phoenix/PhoenixContext';

export default function AdminContainer(props) {
  const loggedInUser = props.loggedInUser;
  const [orgTree, setOrgTree] = useState(null);
  const [error, setError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const [showCreateDepartmentModal, setShowCreateDepartmentModal] = useState(false);

  const history = useHistory();

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);

  async function loadOrgTree() {
    const response = await organizationController.getOrgTree();
    if (response.body !== undefined) {
      setOrgTree(response.body.orgTree);
    } else {
      setError(response.error || "Something went wrong.")
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(true);
    }, 1000 * 3);

    loadOrgTree();
    // eslint-disable-next-line
  }, []);

  if (!loggedInUser.isGlobalAdmin) {
    window.location.pathname = "/"
    return null
  }

  if (error !== null) {
    return <div className="container">
      <Link to={"/"}
        style={{
          fontWeight: 650
        }}>
        <img src={backButtonImage} alt="Back Button" style={{ height: 20, marginRight: 5 }} />Back to Consumer Site
      </Link>
      <p>{error}</p>
    </div>
  }

  if (orgTree === null && showLoading) {
    return <FadeIn>
      <div className="container">
        <p>Loading...</p>
      </div>
    </FadeIn>
  }

  if (orgTree === null) return null;

  return <FadeIn>
    <div style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Link to={"/"}
        style={{
          fontWeight: 650
        }}>
        <img src={backButtonImage} alt="Back Button" style={{ height: 20, marginRight: 5 }} />Back to Consumer Site
      </Link>

      <a href="#createDepartment"
        onClick={(e) => {
          e.preventDefault();
          setShowCreateDepartmentModal(true);
        }}
        style={{
          fontWeight: 650,
          marginLeft: 10
        }}>
        <img src={accountImage} alt="Create Department Icon" style={{ height: 20, marginRight: 5 }} />Create Department
      </a>

      <a href="#createUser"
        style={{
          fontWeight: 650,
          marginLeft: 10
        }}
        onClick={(e) => {
          e.preventDefault();
          history.push(`/admin/user/new`)
        }}>
        <img src={accountImage} alt="Create User Icon" style={{ height: 20, marginRight: 5 }} />Create User
      </a>

      <a href="#businessConfig"
        style={{
          fontWeight: 650,
          marginLeft: 10
        }}
        onClick={(e) => {
          e.preventDefault();
          history.push(`/admin/businessconfig`)
        }}>
        <img src={accountImage} alt="Business Config Icon" style={{ height: 20, marginRight: 5 }} />Business Config
      </a>

      <h1 style={{ fontWeight: 'bold' }}>Goals Administration Portal</h1>

      <OrgChart tree={orgTree} fullWidth={true} canClick={() => {
        return true
      }} onClick={(node) => {
        history.push(`/admin/team/${node.id}`)
      }} />

      <CreateOrganizationModal loggedInUser={loggedInUser} 
        orgTree={orgTree} 
        show={showCreateDepartmentModal}
        hide={() => {
          setShowCreateDepartmentModal(false)
        }}
        onRequireOrgTreeRefresh={() => {
          loadOrgTree();
        }} />
    </div>
  </FadeIn>
}
